import * as React from "react"
import { MdAdd } from "react-icons/md"
import { useTracker } from "@modules/analytics"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { EmptyState, EmptyStatePrimaryAction } from "gatsby-interface"
import { sites as text } from "@modules/locales/default.js"
import { recordCreateSite } from "@modules/fullStory"
import {
  getPathToCreateSite,
  getPathToSelectProvider,
  isConnectedWithGithub,
} from "@modules/site/create/shared/utils"
import { useOrganizationPermissionsFieldsFragment } from "@modules/organization/fragments.generated"
import { useAvailableSourceProvidersQuery } from "@modules/site/create/Import/queries.generated"

export type SitesEmptyStateProps = {
  organizationId: string
}

export function SitesEmptyState({ organizationId }: SitesEmptyStateProps) {
  const { trackButtonClicked } = useTracker()
  const { data } = useOrganizationPermissionsFieldsFragment(organizationId)
  const hasCreateSitesPermissions = data?.permissions?.sites?.create ?? false
  const { data: providersData } = useAvailableSourceProvidersQuery()

  const connectedWithGithub = isConnectedWithGithub(
    providersData?.availableSourceProviders
  )

  const createSitePath = connectedWithGithub
    ? getPathToCreateSite(organizationId)
    : getPathToSelectProvider(organizationId)

  return (
    <EmptyState
      heading={
        hasCreateSitesPermissions
          ? text.headers.noSites
          : text.headers.noSitesViewer
      }
      headingAs="h2"
      text={
        hasCreateSitesPermissions
          ? text.messages.noSitesDescription
          : text.messages.noSitesDescriptionViewer
      }
      graphic={<EmptyStateGraphic />}
      variant="BORDERED"
      primaryAction={
        hasCreateSitesPermissions ? (
          <EmptyStatePrimaryAction
            to={createSitePath}
            onClick={() => {
              recordCreateSite()
              trackButtonClicked("Create new site", {})
            }}
            rightIcon={<MdAdd />}
            data-cy="create-site-button"
          >
            {text.actions.addSite}
          </EmptyStatePrimaryAction>
        ) : null
      }
    />
  )
}
