import * as React from "react"
import { paymentHistory as paymentHistoryText } from "@modules/locales/default.js"
import { Text, ThemeCss, Button } from "gatsby-interface"

const textCss: ThemeCss = theme => ({
  color: theme.colors.grey[80],
})

const introCss: ThemeCss = theme => [
  textCss(theme),
  {
    marginBottom: theme.space[5],
  },
]

export type PaymentHistoryProps = {
  stripeCustomerId: string
}

async function createStripeCustomerPortal(stripeCustomerId: string) {
  const stripeRes = await fetch(
    `/api/create-stripe-customer-session?stripeCustomerId=${stripeCustomerId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
  const stripeJson = await stripeRes.json()

  window.open(stripeJson.redirectUrl)
}

export function PaymentHistory({ stripeCustomerId }: PaymentHistoryProps) {
  return (
    <React.Fragment>
      <Text size="S" css={introCss}>
        {paymentHistoryText.messages.viewYourPaymentHistory}
      </Text>

      <Button onClick={() => createStripeCustomerPortal(stripeCustomerId)}>
        {paymentHistoryText.actions.goToPortal}
      </Button>
    </React.Fragment>
  )
}
