import * as React from "react"
import { Modal, ModalCard, Button } from "gatsby-interface"
import {
  ResendMemberInviteModal,
  RevokeMemberInviteModal,
} from "./MemberActionModals"
import { Contributor as ContributorType } from "@modules/graphql/types"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { manageContributors as text } from "@modules/locales/default.js"

export type InviteeActionsProps = {
  member: ContributorType
  resourceId: string
}

export function InviteeActions({ member, resourceId }: InviteeActionsProps) {
  const { trackSegment, trackButtonClicked } = useTracker()
  const [isReinviteModalOpened, setIsReinviteModalOpened] = React.useState(
    false
  )
  const [isRevocationModalOpened, setIsRevocationModalOpened] = React.useState(
    false
  )

  return (
    <div
      css={theme => ({
        display: `flex`,
        flexWrap: `nowrap`,
        // Visual alignment with member management dropdown MdVert icon
        marginRight: theme.space[4],
      })}
    >
      <Button
        size={`M`}
        variant={`SECONDARY`}
        css={theme => ({
          marginRight: theme.space[2],
        })}
        onClick={() => {
          trackButtonClicked(`Open resend member modal`, {
            uiSource: `Members tab - Manage invitee`,
          })
          trackSegment({
            type: SegmentEventType.Track,
            event: `Open resend member modal`,
            properties: {
              location: `Members tab - Manage invitee`,
            },
          })
          setIsReinviteModalOpened(true)
        }}
      >
        {text.actions.resendInvite}
      </Button>
      <Button
        size={`M`}
        variant={`SECONDARY`}
        onClick={() => {
          trackButtonClicked(`Open revoke member invite modal`, {
            uiSource: `Members tab - Manage invitee`,
          })
          trackSegment({
            type: SegmentEventType.Track,
            event: `Open revoke member invite modal`,
            properties: {
              location: `Members tab - Manage invitee`,
            },
          })
          setIsRevocationModalOpened(true)
        }}
      >
        {text.actions.revokeInvite}
      </Button>

      {isReinviteModalOpened && (
        <Modal
          isOpen={isReinviteModalOpened}
          aria-label={text.headers.resendInvite}
          onDismiss={() => setIsReinviteModalOpened(false)}
        >
          <ModalCard>
            <ResendMemberInviteModal
              closeModal={() => setIsReinviteModalOpened(false)}
              contributorId={member.id}
              email={member.email}
            />
          </ModalCard>
        </Modal>
      )}

      {isRevocationModalOpened && (
        <Modal
          isOpen={isRevocationModalOpened}
          aria-label={text.headers.revokeInvite}
          onDismiss={() => setIsRevocationModalOpened(false)}
          type="error"
        >
          <ModalCard>
            <RevokeMemberInviteModal
              closeModal={() => setIsRevocationModalOpened(false)}
              resourceId={resourceId}
              contributorId={member.id}
              email={member.email}
            />
          </ModalCard>
        </Modal>
      )}
    </div>
  )
}
