import React from "react"
import { Link } from "gatsby"
import { NumberBadge, Button, tabsStyles } from "gatsby-interface"

/*

  Note: Because unregular mobile page padding `1.4rem` (the value not exists in theme.space) [1]
  and some subtle relation between page padding vs scrollable container for mobile
  and link paddings for nice looking focus outline there are plenty of hard coded 'space' value
  I believe that in the next iteration, together with fixing the [1] the values could be rewrite
  to use space design tokens.

*/

export function TabsNav(props) {
  return (
    <nav
      css={theme => [
        tabsStyles.tabs(theme),
        {
          margin: `0 auto`,
          maxWidth: `80rem`,
          paddingTop: `${theme.space[3]}`,
        },
      ]}
      {...props}
    />
  )
}

TabsNav.Tabs = function(props) {
  return (
    <ul
      css={theme => [
        tabsStyles.tabList(theme),
        {
          padding: `0 1rem`,

          "&:after": {
            background: theme.colors.blackFade[10],
            left: `1.4rem`,
            width: `calc(100% - 2.8rem)`,
          },

          [theme.mediaQueries.tablet]: {
            padding: `0 1.6rem`,
            "&:after": {
              left: `2rem`,
              width: `calc(100% - 4rem)`,
            },
          },
        },
      ]}
      {...props}
    />
  )
}

TabsNav.Tab = function({ isLast, ...rest }) {
  return (
    <li
      css={theme => [
        tabsStyles.tab(theme),
        {
          display: isLast ? `flex` : undefined,
          flexGrow: isLast ? 1 : undefined,
          justifyContent: isLast ? `flex-end` : undefined,
        },
      ]}
      {...rest}
    />
  )
}

TabsNav.Link = function(props) {
  return (
    <Link
      css={theme => [
        tabsStyles.tabButton(theme),
        {
          textDecoration: `none`,
          "&:hover": {
            textDecoration: `none`,
          },
        },
      ]}
      {...props}
    />
  )
}

TabsNav.NoLink = function(props) {
  return (
    <span
      css={theme => [
        tabsStyles.tabButton(theme),
        tabsStyles.tabButtonSelected(theme),
      ]}
      {...props}
    />
  )
}

TabsNav.Chip = function(props) {
  return (
    <NumberBadge
      tone="NEUTRAL"
      css={theme => ({
        marginLeft: theme.space[3],
      })}
      {...props}
    />
  )
}

TabsNav.Button = function(props) {
  return (
    <Button
      variant="GHOST"
      size="S"
      type="button"
      css={theme => [tabsStyles.tabButton(theme)]}
      {...props}
    />
  )
}

TabsNav.iconStyle = theme => ({
  marginLeft: theme.space[3],
  color: theme.colors.blackFade[30],
})
