import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import {
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Button,
} from "gatsby-interface"
import { paymentInformation as text } from "@modules/locales/default.js"
import { StyledForm } from "@modules/ui/components/Primitives"

const nameCss = theme => ({
  color: theme.colors.purple[60],
})

export function Resubscribe({
  closeModal,
  name,
  organizationId,
  canResubscribe,
}) {
  return (
    <StyledModal variant="ACTION">
      <StyledModalHeader onCloseButtonClick={closeModal}>
        {text.resubscribe} <strong css={nameCss}>{name}</strong>
        {`?`}
      </StyledModalHeader>
      <StyledModalBody>
        {canResubscribe ? text.resubscribeToManage : text.ownerMustResubscribe}
        {canResubscribe && (
          <StyledForm
            onSubmit={e => {
              e.preventDefault()
              closeModal()
              navigate(`/dashboard/pricing/${organizationId}`)
            }}
          >
            <StyledModalActions>
              <Button
                variant="SECONDARY"
                tone="NEUTRAL"
                type="button"
                onClick={closeModal}
              >
                {text.cancel}
              </Button>
              <Button variant="PRIMARY" type="submit">
                {text.resubscribe}
              </Button>
            </StyledModalActions>
          </StyledForm>
        )}
      </StyledModalBody>
    </StyledModal>
  )
}
Resubscribe.propTypes = {
  closeModal: PropTypes.func,
  name: PropTypes.string,
  organizationId: PropTypes.string,
  canResubscribe: PropTypes.bool,
}
