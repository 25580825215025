import * as React from "react"
import { sitesList as sitesText } from "@modules/locales/default.js"
import {
  Spacer,
  ThemeCss,
  BaseAnchor,
  EmptyState,
  Heading,
} from "gatsby-interface"
import { listCss } from "./AllOrganizationsSitesList.styles"
import { SiteCard } from "@modules/site/card/components/SiteCard"

import { MdOpenInNew } from "react-icons/md"
import {
  SourceControlRepository,
  SourceControlProvider,
  Site,
} from "@modules/graphql/types"
import { useFlaggedDictionary, interpolateMessage } from "@modules/locales"
import {
  SourceControlProviderIcon,
  sourceControlProviderLabels,
} from "@modules/sourceControlProvider"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { useFlags } from "@modules/featureFlags"
import { usePinnedSites } from "../hooks/usePinnedSites"
import Loading from "@modules/ui/components/Loading"
import { Waypoint } from "react-waypoint"

const repositoryNameCss: ThemeCss = _ => ({
  display: "flex",
  alignItems: "center",
  flexWrap: `wrap`,
})

const repositoryTitleCss: ThemeCss = theme => ({
  margin: 0,
  marginRight: theme.space[4],
  padding: 0,
})

const repositorylinkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.purple[50],
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  "&:hover,&:focus": {
    textDecoration: "underline",
  },
  marginTop: theme.space[3],
})

export type SiteListWithRepositoriesProps = {
  repositories: SourceControlRepository[]
  organizationId: string
  searchTerm?: string
  state?: `SEARCHING` | `DEFAULT`
  loadMore?: () => void
  loadingMore?: boolean
  searchText?: string
  debouncedSearchText?: string
}

export function SiteListWithRepositories({
  repositories,
  organizationId,
  searchTerm = "",
  state = `DEFAULT`,
  loadMore,
  loadingMore,
  searchText = ``,
  debouncedSearchText,
}: SiteListWithRepositoriesProps) {
  const { flags } = useFlags()
  const flaggedText = useFlaggedDictionary(sitesText, `spaces`)
  const { pinnedSitesIdsHash, pinSite } = usePinnedSites()

  const pinnedSites = React.useMemo(() => {
    const sites = []

    for (const repo of repositories) {
      for (const site of repo.sites ?? []) {
        if (pinnedSitesIdsHash[site.id]) {
          sites.push(site)
        }
      }
    }

    return sites
  }, [repositories, pinnedSitesIdsHash])

  const emptyStateCondition = repositories.length === 0 && searchText

  if (emptyStateCondition) {
    return state === `SEARCHING` ? (
      <React.Fragment>
        <Spacer size={5} />
        <EmptyState
          heading={flaggedText.headers.noMatchingSites}
          text={interpolateMessage<"searchTerm">(
            flaggedText.messages.noMatchingSites,
            { searchTerm: searchText }
          )}
          headingAs="h2"
          graphic={<EmptyStateGraphic />}
          variant="BORDERED"
        />
      </React.Fragment>
    ) : null
  }

  const renderSite = (site: Site) => {
    return (
      <li key={site.id}>
        <SiteCard
          {...site}
          name={site.publicName || site.name}
          organizationId={organizationId}
          isPinned={pinnedSitesIdsHash[site.id]}
          onPinSite={() => pinSite(site.id)}
          enablePinning={flags.pinnedSites}
        />
      </li>
    )
  }

  return (
    <React.Fragment>
      {flags.pinnedSites && pinnedSites.length > 0 && (
        <React.Fragment>
          <Spacer size={5} />
          <Heading>Pinned sites</Heading>
          <ul css={listCss}>{pinnedSites.map(renderSite)}</ul>
          <Spacer size={5} />
          <hr css={theme => ({ backgroundColor: theme.colors.grey[30] })} />
        </React.Fragment>
      )}
      <ul css={listCss}>
        {repositories.map(repo => {
          const provider = repo.provider || SourceControlProvider.Github

          return (
            <li key={repo.id}>
              <div css={repositoryNameCss}>
                <SourceControlProviderIcon sourceControlProvider={provider} />
                <Spacer size={2} direction="horizontal" />
                <h2 css={repositoryTitleCss}>{repo.name}</h2>
                <BaseAnchor
                  css={repositorylinkCss}
                  target="_blank"
                  href={repo.url}
                  aria-label={interpolateMessage<"provider">(
                    flaggedText.labels.repositoryLinkAria,
                    { provider: sourceControlProviderLabels[provider] }
                  )}
                >
                  {flaggedText.labels.repositoryLink}
                  <Spacer size={2} direction="horizontal" />
                  <MdOpenInNew />
                </BaseAnchor>
              </div>

              <ul css={listCss}>{(repo.sites || []).map(renderSite)}</ul>
            </li>
          )
        })}
      </ul>

      {(debouncedSearchText !== searchText || loadingMore) && (
        <div css={{ textAlign: "center" }}>
          <Spacer size={3} />
          <Loading variant="baby" message={`Loading sites...`} />
        </div>
      )}
      <Waypoint onEnter={loadMore} />
    </React.Fragment>
  )
}
