import * as React from "react"
import { Contributor } from "@modules/graphql/types"
import { MemberCard } from "./MemberCard"
import { ThemeCss } from "gatsby-interface"

export type MembersListProps = {
  members: Contributor[]
  organizationId: string
  permissionToInvite: boolean
  permissionToRead: boolean
  permissionToDelete: boolean
}

export function MembersList({
  members,
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
}: MembersListProps) {
  return (
    <ul css={listCss}>
      {members.map(member => (
        <MemberCard
          key={member.id}
          member={member}
          organizationId={organizationId}
          permissionToInvite={permissionToInvite}
          permissionToRead={permissionToRead}
          permissionToDelete={permissionToDelete}
        />
      ))}
    </ul>
  )
}

/* styles */

const listCss: ThemeCss = _theme => ({
  display: `flex`,
  flexDirection: `column`,
  margin: 0,
})
