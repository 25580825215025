import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { Button } from "gatsby-interface"
import { MdAdd } from "react-icons/md"
import {
  StandardSingleColumn,
  PageWithTabsContentSection,
} from "@modules/ui/layouts/Containers"
import BillingStatus from "@modules/billing/shared/components/BillingStatus"
import IdleStatus from "@modules/billing/shared/components/IdleStatus"
import OverageStatus from "@modules/billing/shared/components/OverageStatus"
import Searchbar from "@modules/site/shared/components/Searchbar"
import {
  sites as sitesText,
  sitesList as sitesListText,
} from "@modules/locales/default.js"
import { OrganizationStatus as OrganizationStatusConstants } from "@modules/graphql/types"
import { useTracker, GAEventType, BigQueryEventType } from "@modules/analytics"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { recordCreateSite } from "@modules/fullStory"
import { SiteListWithRepositories } from "./SiteListWithRepositories"
import {
  getPathToCreateSite,
  getPathToSelectProvider,
  isConnectedWithGithub,
} from "@modules/site/create/shared/utils"
import { SitesEmptyState } from "@modules/organization/shared/components/SitesEmptyState"
import { useAvailableSourceProvidersQuery } from "@modules/site/create/Import/queries.generated"

const divStyles = theme => ({
  display: `flex`,
  marginBottom: theme.space[7],

  "&:last-of-type, &:empty": {
    marginBottom: `0`,
  },
})

const propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export const filterRepositories = (repositories = [], criteria = "") => {
  const filterSites = repo => ({
    ...repo,
    sites: repo.sites.filter(site =>
      site.publicName.toLowerCase().includes(criteria.toLocaleLowerCase())
    ),
  })

  const hasSites = repo => repo.sites.length !== 0

  return repositories.map(filterSites).filter(hasSites)
}

function SingleOrganizationSitesList({
  organizationId,
  organizationDetails,
  organizationRepositories,
  loadMore,
  loadingMore,
  searchText,
  debouncedSearchText,
  setSearchText,
  numberOfSites,
}) {
  const { trackBigQuery, trackGoogleAnalytics } = useTracker()
  const [trackedInputEvent, setTrackedInputEvent] = useState(false)
  const data = organizationDetails
  const onSearchInputChange = setSearchText

  const { status, billing, permissions, enforceCap } = data

  const numOfSites = numberOfSites

  const orgIsIdle = status === OrganizationStatusConstants.Idle

  const orgIsOverage =
    enforceCap && status === OrganizationStatusConstants.Overage

  const { data: providersData } = useAvailableSourceProvidersQuery()

  const connectedWithGithub = isConnectedWithGithub(
    providersData?.availableSourceProviders
  )

  const createSiteNextStepPath = connectedWithGithub
    ? getPathToCreateSite(organizationId)
    : getPathToSelectProvider(organizationId)

  useEffect(() => {
    trackBigQuery({
      eventType: BigQueryEventType.PageViewed,
      uiSource: `Organization Sites`,
    })
  }, [])

  return (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        <div css={divStyles}>
          <BillingStatus
            orgId={organizationId}
            billing={billing}
            showLink={permissions?.billing?.create ?? false}
            uiSource="Organization sites list"
          />
          {orgIsIdle && (
            <IdleStatus
              orgId={organizationId}
              status={status}
              showLink={permissions?.organization?.edit ?? false}
              uiSource="Organization sites list"
            />
          )}
          {orgIsOverage && (
            <OverageStatus
              orgId={organizationId}
              status={status}
              showLink={permissions?.organization?.edit ?? false}
              uiSource="Organization sites list"
            />
          )}
        </div>
        {numOfSites > 0 || searchText || debouncedSearchText ? (
          <Fragment>
            <div css={{ ...divStyles, display: `flex` }}>
              <Searchbar
                ariaLabel={sitesListText.labels.searchSites}
                placeholder={sitesListText.labels.searchSites}
                value={searchText}
                searching={debouncedSearchText !== searchText || loadingMore}
                onChange={e => {
                  onSearchInputChange(e.target.value)
                  if (!trackedInputEvent) {
                    trackGoogleAnalytics({
                      eventType: GAEventType.Input,
                      category: `Product Engagement - Dashboard`,
                      label: {
                        loc: `search`,
                        text: `search sites`,
                      },
                    })
                    setTrackedInputEvent(true)
                  }
                }}
              />
              <OrganizationPermissions
                id={organizationId}
                resource="sites"
                action="create"
              >
                <Button
                  size={`M`}
                  onClick={() => {
                    navigate(createSiteNextStepPath)
                    recordCreateSite()
                    trackBigQuery({
                      eventType: BigQueryEventType.ButtonClicked,
                      buttonName: "Create new site",
                      organizationId,
                      uiSource: `Org Site List`,
                    })
                  }}
                  rightIcon={<MdAdd />}
                  css={theme => {
                    return {
                      flexShrink: `0`,

                      [theme.mediaQueries.tablet]: {
                        marginLeft: theme.space[4],
                      },
                    }
                  }}
                >
                  {sitesText.actions.addSite}
                </Button>
              </OrganizationPermissions>
            </div>

            <SiteListWithRepositories
              organizationId={organizationId}
              organizationBilling={billing}
              repositories={organizationRepositories}
              state={searchText ? `SEARCHING` : `DEFAULT`}
              loadMore={loadMore}
              loadingMore={loadingMore}
              searchText={searchText}
              debouncedSearchText={debouncedSearchText}
            />
          </Fragment>
        ) : (
          <SitesEmptyState organizationId={organizationId} />
        )}
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}

SingleOrganizationSitesList.propTypes = propTypes

export default SingleOrganizationSitesList
