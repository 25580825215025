import * as React from "react"
import { Notification, Link } from "gatsby-interface"
import { MdArrowForward, MdWarning } from "react-icons/md"

export type PlatformLmitOverageNotificationProps = {
  message: React.ReactNode
  link: string
  orgName?: string
}

export function PlatformLmitOverageNotification({
  message,
  link,
  orgName,
}: PlatformLmitOverageNotificationProps) {
  const beginning = orgName ? (
    <React.Fragment>
      The <strong>{orgName}</strong> workspace
    </React.Fragment>
  ) : (
    `This site belongs to the workspace, which `
  )

  return (
    <Notification
      Icon={MdWarning}
      variant="SECONDARY"
      tone="WARNING"
      content={
        <div>
          {beginning} is using {message} allocation. Please{" "}
          <Link variant="SIMPLE" to={link}>
            upgrade your hosting plan <MdArrowForward />
          </Link>
        </div>
      }
    />
  )
}
