import * as React from "react"
import AddMemberAction from "./AddMemberAction"
import { ThemeCss } from "gatsby-interface"

export type MembersActionsProps = {
  organizationId: string
}

export function MembersActions({ organizationId }: MembersActionsProps) {
  return (
    <div css={containerCss}>
      <AddMemberAction organizationId={organizationId} />
    </div>
  )
}

/* styles */

const containerCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `flex-end`,
  paddingBottom: theme.space[6],
})
