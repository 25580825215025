import * as React from "react"
import { keyframes } from "@emotion/core"
import { MdClose, MdAdd, MdArrowForward } from "react-icons/md"
import {
  Button,
  ThemeCss,
  StyledPanelBodySection,
  StyledPanelActions,
  Text,
} from "gatsby-interface"
import {
  addMembers as addMembersText,
  ui as uiText,
} from "@modules/locales/default.js"
import { CloudRole } from "@modules/graphql/types"
import { StyledForm } from "@modules/ui/components/Primitives"
import {
  MemberFieldsGroup,
  MemberFieldsValues,
  MemberFieldsGroupProps,
} from "./MemberFieldsGroup"
import { interpolateMessage } from "@modules/locales"
import { useMembersValidation } from "./InviteMembersForm.helpers"
import { useTracker, SegmentEventType } from "@modules/analytics"

const introCss: ThemeCss = theme => ({
  marginBottom: theme.space[6],
})

const initialMemberValues: MemberFieldsValues = {
  email: ``,
  role: CloudRole.Reader,
  accessAllSites: true,
  sites: [],
}

export type InviteMembersFormProps = {
  organizationId: string
  onSubmit: (invitees: MemberFieldsValues[]) => void
  onCancel: () => void
  loading: boolean
}

export function InviteMembersForm({
  organizationId,
  onSubmit,
  onCancel,
  loading,
}: InviteMembersFormProps) {
  const { trackSegment, trackButtonClicked } = useTracker()
  const [formState, dispatch] = React.useReducer(formStateReducer, {
    members: [initialMemberValues],
  })
  const [validateForm, errors] = useMembersValidation(organizationId)

  // Run initial validation
  React.useEffect(() => {
    validateForm(formState)
  }, [])

  React.useEffect(() => {
    /**
     * We use timeout here to debounce validation
     * When the user types in the email field, "formState" is going to change very often,
     * and with multiple members it can noticeably slow everything down
     *
     * Debouncing validation allows to mitigate this problem
     */
    const validation = setTimeout(() => validateForm(formState), 400)

    return () => {
      clearTimeout(validation)
    }
  }, [formState])

  return (
    <StyledForm
      onSubmit={e => {
        e.preventDefault()
        if (!validateForm(formState)) {
          return
        }
        onSubmit(formState.members)
      }}
      css={{
        flexGrow: 1,
        display: `flex`,
        flexDirection: `column`,
      }}
    >
      <div css={{ flexGrow: 1 }}>
        <StyledPanelBodySection>
          <Text css={introCss} size="S">
            {addMembersText.messages.addMembersDescription}
          </Text>
          {formState.members.map((memberValues, idx) => {
            return (
              <MemberFields
                key={idx}
                organizationId={organizationId}
                idx={idx}
                values={memberValues}
                dispatch={dispatch}
                errors={errors[`members[${idx}]`]}
                focusOnMount={idx > 0}
              />
            )
          })}
          <Button
            size="M"
            variant="SECONDARY"
            rightIcon={<MdAdd />}
            type="button"
            onClick={() => {
              dispatch({
                type: `ADD`,
                payload: initialMemberValues,
              })
              trackButtonClicked(`Added additional member field`, {
                uiSource: `Members tab - "Add Members" modal`,
              })
              trackSegment({
                type: SegmentEventType.Track,
                event: `Added additional member field`,
                properties: {
                  location: `Members tab - "Add Members" modal`,
                },
              })
            }}
          >
            {addMembersText.actions.addMember}
          </Button>
        </StyledPanelBodySection>
      </div>
      <StyledPanelActions>
        <Button
          variant="SECONDARY"
          tone="NEUTRAL"
          type="reset"
          onClick={() => {
            trackButtonClicked(`Cancelled adding new members`, {
              uiSource: `Members tab - "Add Members" modal`,
            })
            trackSegment({
              type: SegmentEventType.Track,
              event: `Cancelled adding new members`,
              properties: {
                location: `Members tab - "Add Members" modal`,
              },
            })
            onCancel()
          }}
        >
          {uiText.actions.cancel}
        </Button>
        <Button
          variant="PRIMARY"
          type="submit"
          loading={loading}
          disabled={Object.keys(errors).length > 0}
          rightIcon={<MdArrowForward />}
        >
          {addMembersText.actions.sendInvites}
        </Button>
      </StyledPanelActions>
    </StyledForm>
  )
}

type FormAction =
  | {
      type: `ADD`
      payload: MemberFieldsValues
    }
  | {
      type: `REMOVE`
      payload: number
    }
  | {
      type: `CHANGE`
      payload: {
        idx: number
        values: MemberFieldsValues
      }
    }

type FormValues = {
  members: MemberFieldsValues[]
}

// TODO investigate if performance issues are caused by gatsby-interface components
// https://app.clubhouse.io/gatsbyjs/story/15304/investigate-if-form-components-from-gatsby-interface-have-performance-issues
/**
 * The performance of the form reduces drastically when using Formik's FieldArray and form components from gatsby-interface
 * Because of that we have to rely on a custom form implementation using a reducer for the form state
 */
function formStateReducer(state: FormValues, action: FormAction): FormValues {
  switch (action.type) {
    case `ADD`: {
      return {
        ...state,
        members: [...state.members, action.payload],
      }
    }
    case `REMOVE`: {
      return {
        ...state,
        members: state.members.filter((_, idx) => idx !== action.payload),
      }
    }
    case `CHANGE`: {
      const newMembers = [...state.members]
      newMembers[action.payload.idx] = action.payload.values

      return {
        ...state,
        members: newMembers,
      }
    }
    default: {
      return state
    }
  }
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const memberFieldsGroupCss: ThemeCss = theme => [
  theme.cardStyles.frame,
  {
    padding: theme.space[5],
    border: `1px solid ${theme.colors.grey[20]}`,
    marginBottom: theme.space[7],
    opacity: 0,
    animation: `${fadeIn} 400ms 100ms forwards`,
  },
]

export const memberFieldsLegendCss: ThemeCss = theme => ({
  width: `100%`,
  display: `flex`,
  float: `left`,
  justifyContent: `space-between`,
  alignItems: `center`,
  padding: 0,
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.body,
  marginBottom: theme.space[2],
  "& + *": {
    clear: `both`,
  },
})

type MemberFieldsProps = Pick<MemberFieldsGroupProps, "values" | "errors"> & {
  organizationId: string
  idx: number
  dispatch: React.Dispatch<FormAction>
  focusOnMount?: boolean
}

const MemberFields = React.memo(function MemberFields({
  organizationId,
  idx,
  values,
  dispatch,
  errors,
  focusOnMount = false,
}: MemberFieldsProps) {
  const { trackSegment, trackButtonClicked } = useTracker()
  const groupRef = React.useRef<HTMLFieldSetElement>(null)

  React.useEffect(() => {
    if (focusOnMount) {
      groupRef.current?.focus()
      groupRef.current?.scrollIntoView()
    }
  }, [])

  return (
    <fieldset
      ref={groupRef}
      tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      css={memberFieldsGroupCss}
    >
      <legend css={memberFieldsLegendCss}>
        <span>
          {interpolateMessage<"num">(addMembersText.headers.memberFieldsGroup, {
            num: idx + 1,
          })}
        </span>
        <Button
          size="M"
          variant="SECONDARY"
          tone="DANGER"
          rightIcon={<MdClose />}
          type="button"
          onClick={() => {
            trackButtonClicked(`Removed additional member field`, {
              uiSource: `Members tab - "Add Members" modal`,
            })
            trackSegment({
              type: SegmentEventType.Track,
              event: `Removed additional member field`,
              properties: {
                location: `Members tab - "Add Members" modal`,
              },
            })
            dispatch({ type: `REMOVE`, payload: idx })
          }}
        >
          {addMembersText.actions.removeMember}
        </Button>
      </legend>
      <MemberFieldsGroup
        organizationId={organizationId}
        name={`members[${idx}]`}
        values={values}
        errors={errors}
        onChange={values =>
          dispatch({
            type: `CHANGE`,
            payload: { idx, values },
          })
        }
      />
    </fieldset>
  )
})
