import * as React from "react"
import { MdArrowForward } from "react-icons/md"
import {
  Button,
  ThemeCss,
  StyledPanelBodySection,
  StyledPanelActions,
  Text,
} from "gatsby-interface"
import {
  modifyMember as modifyMemberText,
  ui as uiText,
} from "@modules/locales/default.js"
import { FormattedMessage } from "@modules/locales"
import { Contributor as ContributorType } from "@modules/graphql/types"
import { StyledForm } from "@modules/ui/components/Primitives"
import { MemberFieldsGroup } from "./MemberFieldsGroup"
import { useMemberValidation } from "./ModifyMemberForm.helpers"
import { memberFieldsGroupCss } from "./InviteMembersForm"
import { MemberFormValues, memberToMemberFields } from "./utils"
import { useTracker, SegmentEventType } from "@modules/analytics"

const introCss: ThemeCss = theme => ({
  marginBottom: theme.space[6],
})

export type ModifyMemberValues = Omit<MemberFormValues, "email">

export type ModifyMemberFormProps = {
  organizationId: string
  member: ContributorType
  onSubmit: (member: ModifyMemberValues) => void
  onCancel: () => void
  loading: boolean
}

export function ModifyMemberForm({
  organizationId,
  member,
  onSubmit,
  onCancel,
  loading,
}: ModifyMemberFormProps) {
  const { trackSegment, trackButtonClicked } = useTracker()
  const [modifiedMember, setModifiedMember] = React.useState<
    ModifyMemberValues
  >(() => memberToMemberFields(member))

  const [validateForm, errors] = useMemberValidation()

  return (
    <StyledForm
      onSubmit={e => {
        e.preventDefault()
        if (!validateForm(modifiedMember)) {
          return
        }

        onSubmit(modifiedMember)
      }}
      css={{
        flexGrow: 1,
        display: `flex`,
        flexDirection: `column`,
      }}
    >
      <div css={{ flexGrow: 1 }}>
        <StyledPanelBodySection>
          <Text css={introCss} size="S">
            <FormattedMessage<"name">
              message={modifyMemberText.messages.modifyMemberDescription}
              values={{
                name: member.name || member.email,
              }}
            />
          </Text>
          <div css={memberFieldsGroupCss}>
            <MemberFieldsGroup
              organizationId={organizationId}
              values={{ ...modifiedMember, email: member.email }}
              errors={errors}
              onChange={values => {
                setModifiedMember(values)
                validateForm(values)
              }}
              includeEmail={false}
            />
          </div>
        </StyledPanelBodySection>
      </div>
      <StyledPanelActions>
        <Button
          variant="SECONDARY"
          tone="NEUTRAL"
          type="reset"
          onClick={() => {
            trackButtonClicked(`Cancelled modifying a member`, {
              uiSource: `Members tab - "Manage Member" modal`,
            })
            trackSegment({
              type: SegmentEventType.Track,
              event: `Cancelled modifying a member`,
              properties: {
                location: `Members tab - "Modify Member" modal`,
              },
            })
            onCancel()
          }}
        >
          {uiText.actions.cancel}
        </Button>
        <Button
          variant="PRIMARY"
          type="submit"
          loading={loading}
          disabled={Object.values(errors).some(error => error !== null)}
          rightIcon={<MdArrowForward />}
        >
          {modifyMemberText.actions.saveChanges}
        </Button>
      </StyledPanelActions>
    </StyledForm>
  )
}
