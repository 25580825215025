// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type WorkspaceMembersQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars["UUID"]
  offset?: Types.Maybe<Types.Scalars["Int"]>
  limit?: Types.Maybe<Types.Scalars["Int"]>
}>

export type WorkspaceMembersQuery = { __typename?: "Query" } & {
  workspaceMembers?: Types.Maybe<
    { __typename?: "WorkspaceMembersResult" } & Pick<
      Types.WorkspaceMembersResult,
      "count"
    > & {
        members?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "Contributor" } & Pick<
                Types.Contributor,
                "id" | "name" | "email" | "avatarUrl" | "contributorRole"
              > & {
                  resources?: Types.Maybe<
                    Array<
                      { __typename?: "CloudResource" } & Pick<
                        Types.CloudResource,
                        "resourceId" | "resourceType" | "role" | "name"
                      >
                    >
                  >
                }
            >
          >
        >
      }
  >
}

export type WorkspaceInvitationsQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars["UUID"]
}>

export type WorkspaceInvitationsQuery = { __typename?: "Query" } & {
  workspaceInvitations?: Types.Maybe<
    { __typename?: "WorkspaceMembersResult" } & Pick<
      Types.WorkspaceMembersResult,
      "count"
    > & {
        members?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "Contributor" } & Pick<
                Types.Contributor,
                "id" | "name" | "email" | "contributorRole"
              >
            >
          >
        >
      }
  >
}

export type ContributorsByOrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type ContributorsByOrganizationQuery = { __typename?: "Query" } & {
  contributorsByOrganization?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "Contributor" } & Pick<
          Types.Contributor,
          "id" | "name" | "email" | "avatarUrl" | "contributorRole"
        > & {
            resources?: Types.Maybe<
              Array<
                { __typename?: "CloudResource" } & Pick<
                  Types.CloudResource,
                  "resourceId" | "resourceType" | "role" | "name"
                >
              >
            >
          }
      >
    >
  >
}

export type CreateInvitationForResourcesMutationVariables = Types.Exact<{
  invitations: Array<Types.InvitationEmailResourceCreateInput>
}>

export type CreateInvitationForResourcesMutation = {
  __typename?: "Mutation"
} & {
  createInvitationForResources: { __typename?: "CreateInvitationResult" } & {
    validation: { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
    contributors?: Types.Maybe<
      Array<
        { __typename?: "Contributor" } & Pick<
          Types.Contributor,
          "id" | "name" | "email" | "avatarUrl" | "contributorRole"
        > & {
            resources?: Types.Maybe<
              Array<
                { __typename?: "CloudResource" } & Pick<
                  Types.CloudResource,
                  "resourceId" | "resourceType" | "role" | "name"
                >
              >
            >
          }
      >
    >
  }
}

export type UpdateContributorRolesMutationVariables = Types.Exact<{
  organizationId: Types.Scalars["UUID"]
  contributorId: Types.Scalars["UUID"]
  resources: Array<Types.UpdateContributorRolesInput>
}>

export type UpdateContributorRolesMutation = { __typename?: "Mutation" } & {
  updateContributorRoles: { __typename?: "UpdateContributorRolesResult" } & {
    validation: { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  }
}

export const WorkspaceMembersDocument = gql`
  query WorkspaceMembers($workspaceId: UUID!, $offset: Int, $limit: Int) {
    workspaceMembers(
      workspaceId: $workspaceId
      offset: $offset
      limit: $limit
    ) {
      count
      members {
        id
        name
        email
        avatarUrl
        contributorRole
        resources {
          resourceId
          resourceType
          role
          name
        }
      }
    }
  }
`

/**
 * __useWorkspaceMembersQuery__
 *
 * To run a query within a React component, call `useWorkspaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMembersQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWorkspaceMembersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >(WorkspaceMembersDocument, baseOptions)
}
export function useWorkspaceMembersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    WorkspaceMembersQuery,
    WorkspaceMembersQueryVariables
  >(WorkspaceMembersDocument, baseOptions)
}
export type WorkspaceMembersQueryHookResult = ReturnType<
  typeof useWorkspaceMembersQuery
>
export type WorkspaceMembersLazyQueryHookResult = ReturnType<
  typeof useWorkspaceMembersLazyQuery
>
export type WorkspaceMembersQueryResult = ApolloReactCommon.QueryResult<
  WorkspaceMembersQuery,
  WorkspaceMembersQueryVariables
>
export const WorkspaceInvitationsDocument = gql`
  query WorkspaceInvitations($workspaceId: UUID!) {
    workspaceInvitations(workspaceId: $workspaceId) {
      count
      members {
        id
        name
        email
        contributorRole
      }
    }
  }
`

/**
 * __useWorkspaceInvitationsQuery__
 *
 * To run a query within a React component, call `useWorkspaceInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInvitationsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useWorkspaceInvitationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WorkspaceInvitationsQuery,
    WorkspaceInvitationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    WorkspaceInvitationsQuery,
    WorkspaceInvitationsQueryVariables
  >(WorkspaceInvitationsDocument, baseOptions)
}
export function useWorkspaceInvitationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WorkspaceInvitationsQuery,
    WorkspaceInvitationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    WorkspaceInvitationsQuery,
    WorkspaceInvitationsQueryVariables
  >(WorkspaceInvitationsDocument, baseOptions)
}
export type WorkspaceInvitationsQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationsQuery
>
export type WorkspaceInvitationsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceInvitationsLazyQuery
>
export type WorkspaceInvitationsQueryResult = ApolloReactCommon.QueryResult<
  WorkspaceInvitationsQuery,
  WorkspaceInvitationsQueryVariables
>
export const ContributorsByOrganizationDocument = gql`
  query ContributorsByOrganization($id: UUID!) {
    contributorsByOrganization(id: $id) {
      id
      name
      email
      avatarUrl
      contributorRole
      resources {
        resourceId
        resourceType
        role
        name
      }
    }
  }
`

/**
 * __useContributorsByOrganizationQuery__
 *
 * To run a query within a React component, call `useContributorsByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributorsByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributorsByOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContributorsByOrganizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContributorsByOrganizationQuery,
    ContributorsByOrganizationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ContributorsByOrganizationQuery,
    ContributorsByOrganizationQueryVariables
  >(ContributorsByOrganizationDocument, baseOptions)
}
export function useContributorsByOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContributorsByOrganizationQuery,
    ContributorsByOrganizationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ContributorsByOrganizationQuery,
    ContributorsByOrganizationQueryVariables
  >(ContributorsByOrganizationDocument, baseOptions)
}
export type ContributorsByOrganizationQueryHookResult = ReturnType<
  typeof useContributorsByOrganizationQuery
>
export type ContributorsByOrganizationLazyQueryHookResult = ReturnType<
  typeof useContributorsByOrganizationLazyQuery
>
export type ContributorsByOrganizationQueryResult = ApolloReactCommon.QueryResult<
  ContributorsByOrganizationQuery,
  ContributorsByOrganizationQueryVariables
>
export const CreateInvitationForResourcesDocument = gql`
  mutation CreateInvitationForResources(
    $invitations: [InvitationEmailResourceCreateInput!]!
  ) {
    createInvitationForResources(invitations: $invitations) {
      validation {
        success
        message
      }
      contributors {
        id
        name
        email
        avatarUrl
        contributorRole
        resources {
          resourceId
          resourceType
          role
          name
        }
      }
    }
  }
`
export type CreateInvitationForResourcesMutationFn = ApolloReactCommon.MutationFunction<
  CreateInvitationForResourcesMutation,
  CreateInvitationForResourcesMutationVariables
>

/**
 * __useCreateInvitationForResourcesMutation__
 *
 * To run a mutation, you first call `useCreateInvitationForResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationForResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationForResourcesMutation, { data, loading, error }] = useCreateInvitationForResourcesMutation({
 *   variables: {
 *      invitations: // value for 'invitations'
 *   },
 * });
 */
export function useCreateInvitationForResourcesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateInvitationForResourcesMutation,
    CreateInvitationForResourcesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateInvitationForResourcesMutation,
    CreateInvitationForResourcesMutationVariables
  >(CreateInvitationForResourcesDocument, baseOptions)
}
export type CreateInvitationForResourcesMutationHookResult = ReturnType<
  typeof useCreateInvitationForResourcesMutation
>
export type CreateInvitationForResourcesMutationResult = ApolloReactCommon.MutationResult<
  CreateInvitationForResourcesMutation
>
export type CreateInvitationForResourcesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateInvitationForResourcesMutation,
  CreateInvitationForResourcesMutationVariables
>
export const UpdateContributorRolesDocument = gql`
  mutation UpdateContributorRoles(
    $organizationId: UUID!
    $contributorId: UUID!
    $resources: [UpdateContributorRolesInput!]!
  ) {
    updateContributorRoles(
      contributorId: $contributorId
      organizationId: $organizationId
      resources: $resources
    ) {
      validation {
        success
        message
      }
    }
  }
`
export type UpdateContributorRolesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateContributorRolesMutation,
  UpdateContributorRolesMutationVariables
>

/**
 * __useUpdateContributorRolesMutation__
 *
 * To run a mutation, you first call `useUpdateContributorRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContributorRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContributorRolesMutation, { data, loading, error }] = useUpdateContributorRolesMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      contributorId: // value for 'contributorId'
 *      resources: // value for 'resources'
 *   },
 * });
 */
export function useUpdateContributorRolesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContributorRolesMutation,
    UpdateContributorRolesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateContributorRolesMutation,
    UpdateContributorRolesMutationVariables
  >(UpdateContributorRolesDocument, baseOptions)
}
export type UpdateContributorRolesMutationHookResult = ReturnType<
  typeof useUpdateContributorRolesMutation
>
export type UpdateContributorRolesMutationResult = ApolloReactCommon.MutationResult<
  UpdateContributorRolesMutation
>
export type UpdateContributorRolesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateContributorRolesMutation,
  UpdateContributorRolesMutationVariables
>
