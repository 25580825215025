import React from "react"
import PropTypes from "prop-types"
import { Router, Redirect } from "@reach/router"

import useSetFSRole from "@modules/fullStory/hooks/useSetFSRole"
import { getPathToOrgDetailsTab } from "@modules/organization/shared/utils"
import OrganizationDetailsTabsNav from "@modules/organization/details/components/OrganizationDetailsTabsNav"
import {
  OrgDetailsTab,
  ORG_DETAILS_DEFAULT_TAB,
} from "@modules/organization/details/constants"
import SingleOrganizationSitesList from "@modules/organization/list/components/SingleOrganizationSitesList"
import OrganizationMembersPane from "@modules/organization/details/components/OrganizationMembersPane"
import OrganizationSettingsPane from "@modules/organization/details/components/OrganizationSettingsPane"

const paneComponentsByTab = {
  [OrgDetailsTab.Sites]: SingleOrganizationSitesList,
  [OrgDetailsTab.Members]: OrganizationMembersPane,
  [OrgDetailsTab.Settings]: OrganizationSettingsPane,
}

const propTypes = {
  organizationId: PropTypes.string.isRequired,
  organizationDetails: PropTypes.object,

  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
}

export default function OrganizationDetailsTabsContainer({
  organizationId,
  organizationDetails,
  organizationRepositories,
  tabs,
  activeTab,
  loadMore,
  loadingMore,
  searchText,
  debouncedSearchText,
  setSearchText,
  numberOfSites,
}) {
  const permissions = organizationDetails && organizationDetails.permissions
  const role = permissions && permissions.role
  // Record user role for site in fullstory
  useSetFSRole(role)

  return (
    <React.Fragment>
      <OrganizationDetailsTabsNav
        tabs={tabs}
        activeTab={activeTab}
        organizationId={organizationId}
        numberOfSites={numberOfSites}
      />
      <Router>
        {tabs.map(({ tab, hasSections }) => {
          const Pane = paneComponentsByTab[tab]
          return (
            <Pane
              key={tab}
              // If there are sections for a tab, delegate their routes to the pane component
              path={hasSections ? `${tab}/*` : tab}
              organizationId={organizationId}
              organizationDetails={organizationDetails}
              organizationRepositories={organizationRepositories}
              loadMore={loadMore}
              loadingMore={loadingMore}
              searchText={searchText}
              debouncedSearchText={debouncedSearchText}
              setSearchText={setSearchText}
              numberOfSites={numberOfSites}
            />
          )
        })}
        {/*
          Index redirect from "base" path (/dashboard/organization/:organizationId)
          to the ORG_DETAILS_DEFAULT_TAB tab
        */}
        <Redirect
          from={`/`}
          to={getPathToOrgDetailsTab(organizationId, ORG_DETAILS_DEFAULT_TAB)}
          noThrow
        />
      </Router>
    </React.Fragment>
  )
}

OrganizationDetailsTabsContainer.propTypes = propTypes
