import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { PlanInformation } from "@modules/billing/shared/components/PlanInformation"
import PaymentInformation from "@modules/payment/components/PaymentInformation"
import { OrganizationStatus } from "@modules/organization/Settings/components/OrganizationStatus"
import usePlanChangeState from "@modules/billing/shared/hooks/usePlanChangeState"
import { OrgSettingsSection } from "../constants"
import { isProduction } from "@modules/env/constants"
import PlanChangeNotification from "@modules/billing/components/PlanChangeNotification"
import { OrganizationNameSection } from "./OrganizationNameSection"
import { useOrganizationSettingsFieldsFragment } from "../fragments.generated"
import { Heading } from "gatsby-interface"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"

function OrganizationSettingsGeneralSection({
  organizationId,
  name,
  billing,
  status,
  permissions,
  sections,
  sectionName,
}) {
  const {
    planUpdateNotificationVisible,
    hidePlanUpdateNotification,
    requestedPlanName,
    clearPlanChangeRequest,
  } = usePlanChangeState(organizationId)

  const currentPlanName = billing && billing.plan && billing.plan.name
  const planUpdateDone =
    requestedPlanName && requestedPlanName === currentPlanName

  useEffect(() => {
    if (planUpdateDone) {
      setTimeout(() => clearPlanChangeRequest(), 10000)
    }
  }, [planUpdateDone])

  const [res, setResponse] = useState({
    success: false,
    err: null,
    creditCard: null,
  })

  return (
    <React.Fragment>
      <Heading as={`h2`} css={visuallyHiddenCss}>
        {sectionName}
      </Heading>
      {planUpdateNotificationVisible && (
        <PlanChangeNotification
          currentPlanName={currentPlanName}
          requestedPlanName={requestedPlanName}
          onDismiss={hidePlanUpdateNotification}
        />
      )}
      {sections.map(({ name: sectionName }) => {
        let cardElement
        switch (sectionName) {
          case OrgSettingsSection.Plan:
            cardElement = (
              <React.Fragment>
                <PlanInformation
                  organizationId={organizationId}
                  permissionToCreate={!!permissions.billing.create}
                />
                {billing && billing.creditCard && (
                  <OrganizationPermissions
                    id={organizationId}
                    resource="billing"
                    action="read"
                  >
                    <PaymentInformation
                      organizationId={organizationId}
                      setResponse={res => setResponse(res)}
                      res={res}
                    />
                  </OrganizationPermissions>
                )}
              </React.Fragment>
            )
            break
          case OrgSettingsSection.Status:
            cardElement = (
              <OrganizationStatus
                organizationStatus={status}
                organizationName={name}
                organizationId={organizationId}
              />
            )
            break
          case OrgSettingsSection.Details:
            cardElement = (
              <OrganizationNameSection
                permissionToEdit={permissions.organization.edit}
                organizationId={organizationId}
                organizationName={name}
              />
            )
            break
          default:
            if (!isProduction) {
              console.error(`Unexpected section found: ${sectionName}`)
            }
            cardElement = null
        }

        return <React.Fragment key={sectionName}>{cardElement}</React.Fragment>
      })}
    </React.Fragment>
  )
}

function OrganizationSettings({ organizationId, sections }) {
  const { data } = useOrganizationSettingsFieldsFragment(organizationId)

  const { name, status, billing, permissions } = data || {}

  return sections.map(({ name: sectionName, active, subsections }) => {
    if (!active) {
      return null
    }
    let settingsSection = null
    switch (sectionName) {
      case OrgSettingsSection.General:
        settingsSection = (
          <OrganizationSettingsGeneralSection
            sectionName={sectionName}
            sections={subsections}
            organizationId={organizationId}
            name={name}
            billing={billing}
            status={status}
            permissions={permissions}
          />
        )
        break
      default:
        if (!isProduction) {
          console.error(`Unexpected section found: ${sectionName}`)
        }
        settingsSection = null
    }

    return <React.Fragment key={sectionName}>{settingsSection}</React.Fragment>
  })
}

OrganizationSettings.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default OrganizationSettings
