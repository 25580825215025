import * as React from "react"
import { useWorkspaceInvitationsQuery } from "../queries.generated"
import { useWorkspaceMembers } from "../hooks/useWorkspaceMembers"
import { SubscriptionStatus } from "@modules/graphql/types"
import { MembersList } from "./MembersList"
import { MembersActions } from "./MembersActions"
import { MembersEmpty } from "./MembersEmpty"
import { MembersPagination } from "./MembersPagination"
import Loading from "@modules/ui/components/Loading"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { orgMembers as text } from "@modules/locales/default.js"

export type WorkspaceMembersProps = {
  organizationId: string
  permissionToInvite: boolean
  permissionToRead: boolean
  permissionToDelete: boolean
  billingStatus: SubscriptionStatus
}

export function Members({
  billingStatus,
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
}: WorkspaceMembersProps) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const isFreePlan = billingStatus === SubscriptionStatus.Free
  const skipQuery = !permissionToRead

  const [
    membersData,
    { loadingMore, loading: membersLoading, loadMore },
  ] = useWorkspaceMembers(organizationId, setError, skipQuery)

  const {
    loading: invitationsLoading,
    data: invitationseData,
  } = useWorkspaceInvitationsQuery({
    variables: {
      workspaceId: organizationId,
    },
    skip: skipQuery,
    onError: e => setError(e),
  })

  const loading = membersLoading || invitationsLoading
  const invited = invitationseData?.workspaceInvitations?.members ?? []
  const active = membersData ?? []
  const members = [...invited, ...active]

  if (!permissionToRead) {
    return <MembersEmpty organizationId={organizationId} />
  }

  if (loading)
    return (
      <Loading
        delay={1000}
        message={text.messages.loadingOrgMembers}
        bufferSize="padded"
      />
    )

  return (
    <React.Fragment>
      {permissionToInvite && !isFreePlan && (
        <MembersActions organizationId={organizationId} />
      )}

      {errorAlert}

      {members.length > 0 && (
        <MembersList
          members={members}
          organizationId={organizationId}
          permissionToInvite={permissionToInvite}
          permissionToRead={permissionToRead}
          permissionToDelete={permissionToDelete}
        />
      )}

      {!loading && (
        <MembersPagination loadingMore={loadingMore} loadMore={loadMore} />
      )}

      {isFreePlan && (
        <MembersEmpty organizationId={organizationId} isFreePlan={isFreePlan} />
      )}
    </React.Fragment>
  )
}
