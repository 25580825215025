import { useWorkspaceMembersQuery } from "../queries.generated"
import * as merge from "deepmerge"
import { NetworkStatus } from "apollo-client"

export const LIMIT = 10

export const useWorkspaceMembers = (
  workspaceId: string,
  setError: ({ message }: { message: string }) => void,
  skip = false
) => {
  const {
    data,
    loading,
    error,
    fetchMore,
    refetch,
    networkStatus,
  } = useWorkspaceMembersQuery({
    variables: {
      workspaceId,
      limit: LIMIT,
      offset: 0,
    },
    fetchPolicy: `cache-and-network`,
    onError: e => setError(e),
    skip,
  })

  const loadMore = () => {
    const allItems = data?.workspaceMembers?.count ?? 0
    const loadedItems =
      (data?.workspaceMembers?.members &&
        data.workspaceMembers.members.length) ??
      0
    const isMore = allItems > loadedItems
    const isFetching = networkStatus === NetworkStatus.fetchMore

    if (!isMore || isFetching) {
      return
    }

    fetchMore({
      variables: {
        workspaceId,
        limit: LIMIT,
        offset: loadedItems,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult
          ? merge(previousResult, fetchMoreResult)
          : previousResult
      },
    })
  }

  return [
    data?.workspaceMembers?.members,
    {
      loading: loading && !data,
      loadingMore: Boolean(loading && data),
      error,
      refetch,
      loadMore,
    },
  ] as const
}
